import { db, serverTimestamp } from './db';
import { removeUndefined, randomString, getURLRoot } from './utils';
import { getLog } from './log';
const log = getLog('links');

export function getUniqueLink(prefix, suffix)
{
  if (suffix)
    return `${prefix}${randomString(8)}`;
  else
    return `${prefix}`;
}

export function createLinkObject(asset, linkId, linkLabel) 
{
  log.log("createLinkObject", asset, linkId, linkLabel);
  let data = removeUndefined({
    id: asset.id,
    name: asset.name,
    type: asset.type,
    visitor: linkLabel,
    created: serverTimestamp(),
    
    // Asset Specific
    numSlides: asset.numSlides,
    videoUrl: asset.url,
    videoId: asset.id,
    filename: asset.filename,
    ownerId: asset.ownerId,
  });
  db.collection('S2Links').doc(linkId).set(data);
  data.id = linkId;
  return data;
}

export function getLinkUrl(linkId)
{
  return `${getURLRoot()}/vid/watch/${linkId}`;
}